<!--多联机--空调入口-->
<!-- 2023/4/6   lht    1.新增页面 2.优化页面缓存机制 -->

<template>
    <div id="airConditionInlet">
        <transition name="slide-fade" mode="out-in">
          <keep-alive :include="include">
            <router-view></router-view>
          </keep-alive>
        </transition>
    </div>
  </template>
  
  <script>
  
  export default {
    data() {
      return {
        include:['airConditionModuleInlet']
      }
    },
  }
  </script>
  
  <style scoped>
  #airConditionInlet {
    width: calc(100% - 30px);
    height: calc(100% - 20px);
    border-radius: 3px;
  }
  .view{
    position: absolute;
    width: 100%;
    height: 100%;
  }
  .slide-fade-enter-active {
    transition: all 0.8s ease;
  }
  .slide-fade-leave-active {
    transition: all 0.3s cubic-bezier(1, 0.5, 0.8, 1);
  }
  .slide-fade-enter,
  .slide-fade-leave-to {
    transform: translateX(10px);
    opacity: 0;
  }
  </style>